<template>
  <span :class="getRequestClass" v-text="getRequestText" />
</template>

<script>
export default {
  props: {
    code: {
      type: Number,
      default: 200,
    },
  },

  computed: {
    getRequestClass() {
      const isOk = this.code >= 200 && this.code <= 300
      const isErr = this.code >= 400 && this.code <= 500
      return {
        'request-status': true,
        'ok': isOk,
        'err': isErr,
        'unknown': !isOk && !isErr,
      }
    },
    getRequestText() {
      const code = this.code
      const isOk = code >= 200 && code <= 300
      const isErr = code >= 400 && code <= 500
      return isOk ? code : isErr ? code : 'N/A'
    },
  },
}
</script>

<style lang="scss" scoped>
.request-status {
  border-radius: 4px;
  padding: 0 4px;
  font-size: 12px;

  &.ok {
    color: #15893c;
    background: #dcf1e3;
    border: 1px solid #15893c;
  }
  &.err {
    color: #d0021b;
    background: #fbeaea;
    border: 1px solid #d0021b;
  }
  &.unknown {
    color: #999;
    background: #f5f5f5;
    border: 1px solid #999;
  }
}
</style>
